import React from 'react';
import WebComponentWrapper from "@ornery/react-web-components";
import { upgradeWebComponents } from './register';
import '../utils/render-html'

interface IRenderedHtmlProps {
    id?: string,
    content?: string,
    missingContentMessage?: string,
    className?: string,
    onRender?: (detail) => void | any,
    children?: React.ReactNode | React.ReactNodeArray,
    onClick?: (event) => {},
    outputClasses?: any
}

class RenderHtml extends React.Component<IRenderedHtmlProps, any> {
    constructor(props) {
        super(props);
    }

    static defaultProps = {
        outputClasses: {},
        missingContentMessage: "message.missing-content"
    }

    componentDidUpdate(prevProps) {
        if (this.props.children !== prevProps.children) {
            this.upgradeWebComponents();
        }
    }

    componentDidMount() {
        this.upgradeWebComponents()
    }

    upgradeWebComponents() {
        const root = document.getElementById("printing-root");
        if (root) {
           upgradeWebComponents(root, this.props.outputClasses)
        }
    }

    render() {
        let {
            id,
            children,
            className,
            content,
            onClick,
            onRender,
        } = this.props;
        const htmlProps = {} as any;
        if (typeof children === "string") {
            htmlProps.dangerouslySetInnerHTML = {__html: children}
            children = null;
        }
        return (<WebComponentWrapper
            tag='render-html'
            id={id}
            onClick={onClick}
            onContentrendered={({detail}) => {
                onRender && onRender(detail)
            }}
            class={`${className} mui-typography_root`}
            html={content}
            shadow="false"
            {...htmlProps}>
            {children}
        </WebComponentWrapper>)
    }
}

export default RenderHtml as any;