import { bindEvents, template } from '@ornery/web-components';
import registerComponent from '../components/register'

export default class RenderHTML extends HTMLElement {
  constructor() {
    super()
  }

  static get observedAttributes() {
    return ['html', 'href']
  }

  attributeChangedCallback() {
    this.applyAttributes()
    this.getContent()
  }

  connectedCallback() {
    if (this.useShadow && !this.shadowRoot) this.attachShadow({ mode: 'open' })
    this.applyAttributes()
    this.getContent()
  }

  applyAttributes() {
    Array.from(this.attributes).forEach(attr => {
      this[attr.name] = attr.value
    })
    Array.from(this.attributes).forEach(attr => {
      this[attr.name] = attr.value
    })
  }

  get useShadow() {
    if (this.hasAttribute('shadow')) {
      let current = this.getAttribute('shadow')
      if (current === 'false') {
        return false
      }
    }
    return true
  }

  getContent() {
    if (this.getAttribute('href')) {
      fetch(this.getAttribute('href'))
        .then(response => response.text())
        .then((str) => this.render(str))
    } else if (this.getAttribute('html') != null) {
      this.render(this.getAttribute('html'))
    } else {
      this.render(this.innerHTML);
    }
  }

  render(markupStr) {
    const root = this.shadowRoot || this;
    root.innerHTML = ''
    const props = this
    const parsed = new DOMParser().parseFromString(markupStr, 'text/html');
    const elements = [...parsed.head.children, ...bindEvents(parsed.body, props).childNodes];
    elements.forEach(n => root.appendChild(n))
    this.dispatchEvent(new CustomEvent('contentrendered', { detail: this }));
  }
}

registerComponent('render-html', RenderHTML)